import React, { useContext } from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'
import Modal, {
  ModalContext,
  ModalContextProvider
} from '@solid-ui-components/Modal'

const MyImage = props => {
  const { setActiveModal } = useContext(ModalContext)
  return (
    <>
      <img
        onClick={() => setActiveModal(props.src)}
        style={{
          maxWidth: `100%`,
          maxHeight: 250,
          borderRadius: 10,
          cursor: 'pointer',
          marginLeft: 5,
          marginRight: 5,
        }}
        {...props}
      />
      <Modal id={props.src} contentStyles={{bg: 'none', boxShadow: 'none'}}>
        <div style={{ textAlign: 'center' }}>
          <img style={{ maxWidth: '100%', borderRadius: '15px' }} {...props} />
        </div>
      </Modal>
    </>
  )
}

const MyComponents = {
  ...components,
  img: MyImage
}

export const PostBody = ({ body }) => {
  return (
    <ModalContextProvider>
      <MDXProvider components={MyComponents}>
        <div style={{ textAlign: 'center' }}>
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </MDXProvider>
    </ModalContextProvider>
  )
}
