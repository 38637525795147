import React from 'react'
import { Card as CardComponent, Container } from 'theme-ui'
import { Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { PostBody } from '@widgets/Post'
import { Layout } from '../../flow-ui-layout/Layout/Layout'
import { PostHead } from '../../flow-ui-widgets/Post'
import Companies from '@solid-ui-blocks/Companies/Block01/Block01'

const dodavatele = [
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/660a75426749f1aeb4bae22bef2c4757/ABB_logo.svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/e40c1baf495886b0938b856cb6f1eba2/alteco-logo.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/a31612c20dfdf462618c11b125a71ffc/DAIKIN_logo (1).svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/54aa742b314c4006e2c10da6499374e8/GEA_Logo_2022.svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/d31cfb1940b50ff105814a48606895c2/Hilti_logo.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/6a3ec3c295d274ad1bae97bea1ee2f3a/LG_logo_(2015).svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/6bed10a402598362cd2cf3cef8d78ab7/logo.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/c224ab47de63dc861f280700a1bab0ae/logo.svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/532e8e56a1e845269a77145ed012cc18/logo (1).svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/087e6cd31201f4cfcb4cdcf410539c74/logo (2).svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/93e3b4f7fda58d6d66b19589d4bfcf99/Logo_of_the_Carrier_Corporation.svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/c30e1d1afbdc12d5fd1ec2b8c5383899/multivac.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/69e9023c10da49713b722098d07c37fd/Pfannenberg_Logo_EN_RGB.svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/e8bff3813bd1a613015fb352f4b2125d/promat-logo-vector.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/170fb57ddd495ef7235856d87344ae69/Samsung_wordmark.svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/20f1e52d8e61b014912d1e3fc704479b/systemair.svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/f41a18747209da7a10e58480b99bcc4c/Toshiba_logo.svg',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/003d59c5a81ea8bd9347f53fb8f60bfb/atrea-logo.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/b755fa4c7099deb4d78988f4d5b858a3/Rosenberg_Ventilatoren_Logo.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/6804222a4098f1b084486f4a1904e451/mandik.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/9ef4785a2999707fb71ca19c58227407/cic.gif',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  },
  {
    identifier: null,
    page: null,
    container: null,
    text: null,
    images: null,
    avatar: null,
    icon: {
      src: '/static/09bccb247e847efa800a8fe686d7f950/elektrodesign.png',
      color: null,
      bg: null,
      size: null
    },
    map: null,
    buttons: null,
    form: null,
    collection: null
  }
]

const Post = ({ data: { post }, ...props }) => {
  const { pageContext: { siteUrl } = {} } = props

  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />

      <Divider />
      <Divider />
      <Divider />
      <Divider />
      <Divider />
      <Divider />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Container variant={'full'} sx={{ textAlign: 'center' }}>
          <PostHead {...post} />
        </Container>
      </Stack>

      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            <PostBody {...post} />
          </CardComponent>
          <Divider />
          <Container id={`dodavatele`} sx={{ textAlign: 'center' }}>
            <Companies
              content={{
                identifier: 'dodavatele',
                page: 'site/index',
                text: [
                  {
                    text: 'Dodavatelé',
                    textGroup: null,
                    color: null,
                    space: null,
                    variant: 'h1'
                  }
                ],
                images: null,
                avatar: null,
                icon: null,
                map: null,
                buttons: null,
                form: null,
                collection: CollectionByName[post.title.trim()]
              }}
            />
          </Container>

          <Divider />
          <Divider />
        </Main>
      </Stack>
    </Layout>
  )
}

const CollectionByName = {
  ['Průmysl a technologie']: [
    dodavatele[2], //daikin
    dodavatele[5], // lg
    dodavatele[10], // carrier
    dodavatele[16], // toshiba
    dodavatele[14], // samsung
    dodavatele[12] // pfannenberg
  ],
  ['Obytné a komerční prostory']: [
    dodavatele[2], //daikin
    dodavatele[5], // lg
    dodavatele[10], // carrier
    dodavatele[16], // toshiba
    dodavatele[14] // samsung
  ],
  ['Tepelná čerpadla']: [
    dodavatele[2], //daikin
    dodavatele[5], // lg
    dodavatele[16], // toshiba
  ],
  ['Laboratoře - odtahy']: [
    dodavatele[1], //alteko
    dodavatele[18], //rosenberg
    dodavatele[0], //abb
    dodavatele[7] //fort plasty
  ],
  ['Požární klapky, ucpávky a potrubí']: [
    dodavatele[19], //mandik
    dodavatele[15], //systemair
    dodavatele[4], //hilti
    dodavatele[13] //promat
  ],
  ['Vzduchotechnika a rekuperace']: [
    dodavatele[15], //systemair
    dodavatele[17], //atrea,
    dodavatele[11], // multivac
    dodavatele[8], // rekuvent
    dodavatele[9], // remak
    dodavatele[3], // gea,
    dodavatele[1], //alteko
    dodavatele[20], //cic
    dodavatele[21] //elektrodesign
  ]
}

export default Post
